import React from "react";

import { useTranslations } from "../hooks/translations";

import styles from "./recaptcha-message.module.scss";

const RecaptchaMessage = () => {
  const t = useTranslations("recaptcha");

  return (
    <div className={styles.message}>
      {t.protectedByGoogle}{" "}
      <a
        href="https://policies.google.com/privacy"
        target="_blank"
        rel="noreferrer noopener"
        className="bp-link bp-accent"
      >
        {t.privacy}
      </a>{" "}
      {t.and}{" "}
      <a
        href="https://policies.google.com/terms"
        target="_blank"
        rel="noreferrer noopener"
        className="bp-link bp-accent"
      >
        {t.terms}
      </a>
      {t.apply ? ` ${t.apply}` : ""}.
    </div>
  );
};

export default RecaptchaMessage;
