import React, { useState, useCallback, useContext, useEffect } from "react";
import { graphql } from "gatsby";
import { useQueryParam, StringParam } from "use-query-params";

import { createNewAccount, doesAccountExist } from "../api/new-account";
import { login } from "../api/auth";
import { useSelf } from "../hooks/self";
import { useNavigateLocalized } from "../hooks/navigate-localized";
import { useTranslations } from "../hooks/translations";
import { REQUIRED, MIN_LENGTH, EMAIL } from "../utils/validators";
import InfoPopupContext from "../info-popup-context";
import SEO from "../components/seo";
import Form from "../components/form";
import FormInput from "../components/form-input";
import Button from "../components/button";
import StatusModal from "../components/status-modal";
import SpinnerOverlay from "../components/spinner-overlay";
import RecaptchaMessage from "../components/recaptcha-message";

export const query = graphql`
  query NewAccountQuery($locale: String!) {
    translations(locale: $locale) {
      pages {
        newAccount
      }
      form
      recaptcha
      ...LayoutTranslations
    }
  }
`;

const FIELDS = {
  email: {
    value: "",
    required: true,
    validators: [
      {
        validate: REQUIRED,
        message: "emailRequired"
      },
      {
        validate: email => !email || EMAIL(email),
        message: "emailFormat"
      }
    ]
  },
  password: {
    value: "",
    required: true,
    validators: [
      {
        validate: REQUIRED,
        message: "passwordRequired"
      },
      {
        validate: MIN_LENGTH(8),
        message: "passwordTooShort"
      }
    ]
  }
};

const NewAccountPage = () => {
  const t = useTranslations("pages.newAccount");

  const [userId] = useQueryParam("token", StringParam);

  const { initializing, user } = useSelf();

  const [accountExists, setAccountExists] = useState(true);

  const [submitting, setSubmitting] = useState(false);

  const [errorStatus, setErrorStatus] = useState(null);

  const [userStatusVerified, setUserStatusVerified] = useState(false);

  const navigateToHome = useNavigateLocalized("/");

  const navigateToLogin = useNavigateLocalized("/login");

  const navigateToNotFound = useNavigateLocalized("/not-found");

  const navigateToDashboard = useNavigateLocalized("/dashboard/");

  const onErrorDialogClose = useCallback(() => {
    if (errorStatus === t.tokenError) {
      navigateToHome();
    } else {
      setErrorStatus(null);
    }
  }, [errorStatus]);

  const verifyAccountDoesNotExist = useCallback(() => {
    doesAccountExist(userId).then(
      exists => {
        if (exists) {
          navigateToLogin({
            state: t.accountAlreadyActive
          });
        } else {
          setAccountExists(false);
        }
      },
      () => {
        setErrorStatus(t.tokenError);
      }
    );
  }, []);

  const verifyNotLoggedIn = useCallback(() => {
    if (user && user.uid === userId) {
      navigateToDashboard();
    } else {
      verifyAccountDoesNotExist();
    }
  }, [user]);

  useEffect(() => {
    if (!userId) {
      navigateToNotFound();
    } else if (!initializing && !userStatusVerified) {
      verifyNotLoggedIn();
      setUserStatusVerified(true);
    }
  }, [initializing, userStatusVerified]);

  const infoPopup = useContext(InfoPopupContext);

  const submitForm = useCallback(
    formData => {
      if (submitting) {
        return null;
      }

      setSubmitting(true);

      return createNewAccount({
        userId,
        ...formData
      })
        .then(
          () => {
            infoPopup.display({
              message: t.success
            });
            // I need to fetch the current user's data. This is a hacky way to do it,
            // but there does not seem to be a better option
            return login(formData);
          },
          err => {
            setErrorStatus(t.submitError);
            throw err;
          }
        )
        .finally(() => {
          setSubmitting(false);
        });
    },
    [submitting, infoPopup]
  );

  return (
    <>
      {accountExists ? (
        <SpinnerOverlay />
      ) : (
        <section>
          <div className="bp-container">
            <SEO title={t.title} description={t.metaDescription} robots="noindex" />
            <h1>{t.heading}</h1>
            <p className="bp-subheading">{t.description}</p>

            <Form fields={FIELDS} onSubmit={submitForm} displayGlobalError errorMessages={t.errors}>
              {({ setFieldValue, fields }) => (
                <>
                  <FormInput
                    id="email"
                    label={t.email}
                    type="email"
                    field={fields.email}
                    onChange={setFieldValue}
                    autoFocus
                  />

                  <FormInput
                    id="password"
                    label={t.password}
                    type="password"
                    field={fields.password}
                    onChange={setFieldValue}
                  />

                  <RecaptchaMessage />

                  <Button type="submit" className="bp-standard bp-submit-button" busy={submitting}>
                    {t.submit}
                  </Button>
                </>
              )}
            </Form>
          </div>
        </section>
      )}

      {errorStatus ? (
        <StatusModal
          error
          heading={errorStatus.heading}
          description={errorStatus.description}
          onClose={onErrorDialogClose}
        />
      ) : null}
    </>
  );
};

export default NewAccountPage;
