import callFunction from "../firebase/functions";

export const createNewAccount = ({ userId, email, password }) =>
  callFunction("createNewAccount", {
    userId,
    email,
    password
  });

export const doesAccountExist = userId =>
  callFunction("doesAccountExist", {
    userId
  }).then(({ data }) => data.accountExists);
